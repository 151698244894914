import React from 'react';

import GlobalStyles from '../styles/GlobalStyles';
import 'normalize.css';
import Header from './Header';
import SEO from './SEO';
import { LocaleProvider } from './localeContext'


export default function Layout({ children, pageContext, data}) {
  return (
    <>
      <GlobalStyles />
      <SEO
        location={pageContext.locale}
      />
      <LocaleProvider locale={pageContext.locale}>
        <Header data={null} />
      </LocaleProvider>
      <div id="main-section">
        {children}
      </div>
    </>
  );
}
