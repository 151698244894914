import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({ children, location, image }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  return (
      <Helmet titleTemplate={`%s - AB Digital Enterprises`}>
          <html lang={location}/>
          <title>{site.siteMetadata.title}</title>
          {/* Fav Icons */}
          <link rel="icon" type="image/ico" href="/favicon.ico"/>
          {/* Meta Tags */}
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta charSet="utf-8"/>
          <meta name="description" content={site.siteMetadata.description}/>
          {/* Open Graph */}
          {location && <meta property="og:url" content={location.href}/>}
          <meta property="og:image" content={image || "/logo.svg"}/>
          <meta property="og:title" content={site.siteMetadata.title} key="ogtitle"/>
          <meta
              property="og:site_name"
              content={site.siteMetadata.title}
              key="ogsitename"
          />
          <meta property="og:description" content={site.siteMetadata.description} key="ogdesc"/>
          <link rel="preconnect" href="https://fonts.googleapis.com"></link>
          <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin
          ></link>
          <link
              href="https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap"
              rel="stylesheet"
          ></link>
          <meta name="robots" content="INDEX,FOLLOW"></meta>
          <meta
              name="google-site-verification"
              content="56CGrjx_pVO9r0534FsJuu-WcnJOWksesTn5Ugcu_I0"
          />
          <script src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=_turnstileCb" defer></script>
          {children}
      </Helmet>
  );
}
